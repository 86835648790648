
.el-table_1_column_4 th {
text-align: right;
}
:deep() .el-table__body-wrapper{
min-height: 350px!important;
}
.min-table{
min-height: 450px;
max-width: 99%!important;
}
.dropdown-item{
height: 33px;
display: flex;
justify-content: flex-start;
align-items: center;
&:hover{
color: #16181b!important;
background-color: #f6f9fc!important;
}
}
:deep() .el-table__empty-block {
display: none!important;
}
.table-empty{
  position: absolute;
}
.modal-landing{
  position: relative;
  background-color: #8dfd36;
  border-radius: 20px;
  padding: 24px;
  max-width: 299px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #012454;
  margin: auto;
  margin-top: 86px;


  .title{
    padding-bottom: 22px ;
    font-size: 15px;
    font-weight: 600;
  }
  .content{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .button-landing{
    min-height: 33px;
    height: auto;
  }
}
.text-link{
  font-size: 13px;
  color: #0373a8;
}

